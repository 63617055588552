<template>
  <svg
    id="wave"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="550"
    height="300"
  >
    <rect width="100%" height="100%" rx="4" ry="4" fill="transparent" />

    <!-- 尺寸 -->
    <!-- 高 -->
    <rect width="14" height="1" x="474" y="30" fill="#666" />
    <rect width="1" height="216" x="480" y="30" fill="#666" />
    <rect width="14" height="1" x="474" y="244" fill="#666" />
    <g>
      <text x="525" y="140" font-size="20" text-anchor="middle" fill="#666">
        {{ data.width1 }}
      </text>
    </g>

    <!-- 宽1 -->
    <rect width="1" height="14" x="100" y="254" fill="#666" />
    <rect width="298" height="1" x="102" y="260" fill="#666" />
    <rect width="1" height="14" x="398" y="254" fill="#666" />
    <g>
      <text x="254" y="285" font-size="20" text-anchor="middle" fill="#666">
        {{ data.width2 }}
      </text>
    </g>

    <!-- 宽2 -->
    <rect width="65" height="1" x="400" y="260" fill="#666" />
    <rect width="1" height="14" x="465" y="254" fill="#666" />
    <g>
      <text x="430" y="285" font-size="20" text-anchor="middle" fill="#666">
        {{ data.width3 }}
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        width1: 10,
        width2: 100,
        width3: 300
      })
    }
  }
};
</script>

<style></style>
