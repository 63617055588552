<template>
  <div class="box">
    <svg viewBox="0 0 550 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="tank-bg" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="#666666" stop-opacity="0.6" />
          <stop offset="18%" stop-color="#666666" stop-opacity="0.3" />
          <stop offset="65%" stop-color="#666666" stop-opacity="0.6" />
          <stop offset="100%" stop-color="#666666" />
        </linearGradient>
        <linearGradient id="line" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="white" stop-opacity="0" />
          <stop offset="100%" stop-color="white" />
        </linearGradient>
      </defs>
      <path
        fill="url(#tank-bg)"
        d="M100 24 L 114 24, 114 16, 106 16, 106 8, 194 8, 194 14, 186 14, 186 24, 398 24 C 490 24, 490 244, 398 244 L 100 244 M 100 244 C 10 240, 10 24, 100 24"
      />
      <rect width="2" height="220" x="99" y="24" fill="url(#line)" />
      <rect width="2" height="220" x="400" y="24" fill="url(#line)" />

      <!-- 尺寸 -->
      <line-item
        :data="{
          width1: data.tankHeight + 'm',
          width2: data.tankLength + 'm',
          width3: data.tankHead + 'm'
        }"
      />

      <rect
        width="68"
        height="206"
        x="116"
        y="32"
        rx="4"
        ry="4"
        fill="white"
        stroke="transparent"
        stroke-width="2"
      />
      <wave x="114" y="30" />
      <g>
        <text
          id="info"
          x="210"
          y="70"
          fill="white"
          font-size="18"
          letter-spacing="1"
        >
          <tspan x="210" y="95">
            温度：
            <tspan font-weight="bold" fill="#F35E4A">
              {{ data.temp || 0 }}
            </tspan>
            ℃
          </tspan>
          <tspan x="210" y="125">
            水位：
            <tspan font-weight="bold" fill="#F35E4A">
              {{ data.waterVolume || 0 }}
            </tspan>
            mm
          </tspan>
          <tspan x="210" y="155">
            油高：
            <tspan font-weight="bold" fill="#F35E4A">
              {{ data.oilVolume || 0 }}
            </tspan>
            mm
          </tspan>
          <tspan x="210" y="185">
            当前库存：
            <tspan font-weight="bold" fill="#F35E4A">
              {{ data.oilMargin || 0 }}
            </tspan>
            L
          </tspan>
        </text>
      </g>
      <g>
        <circle
          cx="70"
          cy="124"
          r="15"
          stroke="#fff"
          stroke-width="2"
          fill="transparent"
        />
        <text x="70" y="130" font-size="18" text-anchor="middle" fill="white">
          {{ data.tankNum || index + 1 }}
        </text>
        <text
          x="70"
          y="164"
          font-size="20"
          text-anchor="middle"
          fill="gold"
          font-weight="bold"
        >
          {{ data.type }}
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import Wave from './wave';
import LineItem from './line';
export default {
  components: {
    Wave,
    LineItem
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        temp: 10,
        waterVolume: 100,
        oilVolume: 300,
        oilMargin: 1000
      })
    },
    index: {
      type: Number,
      default: 1
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
}
</style>
