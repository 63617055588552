<template>
  <page-header :title="siteName" :breadcrumbs="pathMatch">
    <div class="page-header-title">
      <span>{{ siteName }}</span>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="
            visible = true;
            dialogAdd = true;
            viewTank = false;
            RowInfo.siteId = name;
            RowInfo.meterNum = meterNum;
          "
          >新增</el-button
        >
        <el-button type="primary" size="small" @click="back">返回</el-button>
      </div>
    </div>
    <el-row v-if="data.length > 0">
      <el-col :md="6" :sm="12" v-for="(item, index) in data" :key="item.id">
        <div class="tank-box">
          <div class="tank-icon">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top"
              v-if="false"
            >
              <i class="el-icon-edit-outline" @click="handleEditTank(item)"></i>
            </el-tooltip>
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="查看"
              placement="top"
            >
              <i class="el-icon-view" @click="handleViewTank(item)"></i>
            </el-tooltip> -->
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
            >
              <i class="el-icon-delete" @click="handleDeleteTank(item)"></i>
            </el-tooltip>
          </div>
          <tank-box :data="item" :index="index" />
        </div>
      </el-col>
    </el-row>
    <div v-else>
      <div style="color: #999;text-align: center;">没有油罐数据！</div>
    </div>

    <el-dialog
      :title="dialogAdd ? '新增' : '编辑'"
      :visible.sync="visible"
      width="900px"
      custom-class="dialog"
      :close-on-click-modal="false"
      @close="handleCancel"
    >
      <el-form
        ref="form"
        :model="RowInfo"
        label-width="115px"
        :rules="viewTank ? 'none' : rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属加油站" prop="siteId">
              <el-input
                style="width:100%"
                v-model="siteName"
                placeholder="请输入所属加油站"
                disabled
              />
              <!-- <el-select
                style="width:100%"
                v-model="RowInfo.siteId"
                placeholder="请选择所属加油站"
                disabled
              >
                <el-option
                  v-for="(item, index) in siteIdOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="num" label="油罐号">
              <el-input
                style="width:100%"
                v-model="RowInfo.num"
                placeholder="请输入油罐号"
                :disabled="viewTank"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="meterNum" label="液位仪编号">
              <el-input
                style="width:100%"
                v-model="RowInfo.meterNum"
                placeholder="请输入液位仪编号"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="tankHeight" label="油罐高度(m)">
              <el-input
                style="width:100%"
                v-model="RowInfo.tankHeight"
                placeholder="请输入油罐高度(m)"
                :disabled="viewTank"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="tankLength" label="油罐长度(m)">
              <el-input
                style="width:100%"
                v-model="RowInfo.tankLength"
                placeholder="请输入油罐长度(m)"
                :disabled="viewTank"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="tankHead" label="封头长度(m)">
              <el-input
                style="width:100%"
                v-model="RowInfo.tankHead"
                placeholder="请输入封头长度(m)"
                :disabled="viewTank"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="oilCapacity" label="最大容量(L)">
              <el-input
                style="width:100%"
                v-model="RowInfo.oilCapacity"
                placeholder="请输入最大容量(L)"
                :disabled="viewTank"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="type" label="油品">
              <el-select
                style="width:100%"
                v-model="RowInfo.type"
                placeholder="请选择油品"
                :disabled="viewTank"
                @change="GetAllTankerList"
              >
                <el-option
                  v-for="(item, index) in typeOptions"
                  :key="index"
                  :label="item.type"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="inputTime" label="投入时间">
              <el-date-picker
                style="width:100%"
                v-model="RowInfo.inputTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期"
                :disabled="viewTank"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-for="(item, index) in tankOptions" :key="index">
            <el-form-item :label="item.name" :prop="'' + item.id">
              <el-select
                v-model="RowInfo[item.id]"
                multiple
                style="width:100%"
                placeholder="请选择油枪"
                :disabled="viewTank"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="(itex, indx) in item.option"
                  :key="indx"
                  :label="itex.content"
                  :value="itex.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <el-button type="primary" @click="handleSubmit">提交</el-button>
              <el-button @click="handleCancel">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import TankBox from './components/tank';
import {
  GetOilTank,
  OilTankAdd,
  OilTankEdit,
  getTankerAll,
  getPortGunAll
} from '@/api/level_gauge_info';
// import mixins from '@/mixins';
import { getGasData, getTanker, getPortGun, tankDelete } from '@/api/global';
import { isNumber, isDecimal } from '@/utils/validate.js';

export default {
  components: {
    PageHeader,
    TankBox
  },
  data() {
    return {
      data: [],
      typeOptions: [
        { type: '92#', value: '92#' },
        { type: '95#', value: '95#' },
        { type: '93#', value: '93#' },
        { type: '97#', value: '97#' },
        { type: '98#', value: '98#' },
        { type: '101#', value: '101#' },
        { type: '0#', value: '0#' },
        { type: '5#', value: '5#' },
        { type: '-10#', value: '-10#' },
        { type: '-20#', value: '-20#' },
        { type: '-35#', value: '-35#' },
        { type: '-50#', value: '-50#' }
      ],
      roleId: 1,
      siteName: this.$route.query.name,
      siteId: this.$route.query.id,
      name: this.$route.query.name,
      meterNum: this.$route.query.meterNum,
      dialogAdd: true,
      rules: {
        num: [
          {
            required: true,
            // message: '请填写油罐号',
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请填写油罐高度'));
              } else {
                if (isNumber(value)) {
                  callback();
                } else {
                  return callback(new Error('格式不对，只能填写数字'));
                }
              }
            }
          }
        ],
        tankHeight: [
          {
            required: true,
            // message: '请填写油罐高度',
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请填写油罐高度'));
              } else {
                if (isDecimal(value)) {
                  callback();
                } else {
                  return callback(new Error('格式不对，只能填写数字或小数'));
                }
              }
            }
          }
        ],
        // siteId: [{ required: true, message: '请填写所属加油站' }],
        // meterNum: [{ required: true, message: '请填写液位仪编号' }],
        type: [{ required: true, message: '请填写油品' }],
        inputTime: [{ required: true, message: '请填写投入时间' }],
        oilCapacity: [
          {
            required: true,
            // message: '请填写最大容量',
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请填写最大容量'));
              } else {
                if (isDecimal(value)) {
                  callback();
                } else {
                  return callback(new Error('格式不对，只能填写数字或小数'));
                }
              }
            }
          }
        ],
        tankLength: [
          {
            required: true,
            // message: '请填写油罐长度',
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请填写油罐长度'));
              } else {
                if (isDecimal(value)) {
                  callback();
                } else {
                  return callback(new Error('格式不对，只能填写数字或小数'));
                }
              }
            }
          }
        ],
        tankHead: [
          {
            required: true,
            // message: '请填写封头长度',
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请填写封头长度'));
              } else {
                if (isDecimal(value)) {
                  callback();
                } else {
                  return callback(new Error('格式不对，只能填写数字或小数'));
                }
              }
            }
          }
        ]
      },
      visible: false,
      viewTank: false,
      RowInfo: {},
      siteIdOptions: [],
      tankOptions: [],
      tankId: [],
      editOilType: null
    };
  },
  watch: {
    // 'RowInfo.type': function(val) {
    //   // console.log(val)
    //   this.GetTankerList(val);
    // },
    immediate: true
  },
  // mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery();
    // this.GetGasData();
  },
  methods: {
    // 查看
    handleViewTank(val) {
      this.visible = true;
      this.viewTank = true;
      this.RowInfo = {
        ...val
      };
    },
    // 编辑
    handleEditTank(val) {
      // console.log(val);
      this.visible = true;
      this.RowInfo = {
        ...val
      };
      this.editOilType = val.type;
      this.GetAllTankerList(val.type);
    },
    handleDeleteTank(val) {
      var vals = val.id;
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            id: vals
          };
          tankDelete(params).then(res => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: res.msg
              });
              this.handleQuery();
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    async GetAllTankerList(type, falg) {
      this.tankOptions = [];
      this.tankId = [];
      let params = {
        id: this.siteId,
        type: type
      };
      let result;
      if (falg || type === this.editOilType) {
        result = await getTankerAll(params);
      } else {
        result = await getTanker(params);
      }
      if (result.code === 0) {
        // console.log(result.data);
        result.data.map(async item => {
          let params = {
            id: item.id,
            type: type
          };
          this.tankId.push(item.id);
          let portResult;
          if (falg || type === this.editOilType) {
            portResult = await getPortGunAll(params);
          } else {
            portResult = await getPortGun(params);
          }
          let options = [],
            option = [];
          if (portResult.code === 0) {
            option = portResult.data;
            options.push({
              id: item.id,
              name: item.name,
              option: option
            });
            this.tankOptions.push(...options);
            this.tankOptions.sort((a, b) => {
              return a.name.charAt(0) - b.name.charAt(0);
            });
          }
        });

        if (falg || type === this.editOilType) {
          this.RowInfo.tankerGun.map(item => {
            this.RowInfo[item[0]] = item.slice(1);
          });
        }
      }
    },
    GetGasData() {
      getGasData().then(res => {
        if (res.code === 0) {
          this.siteIdOptions = res.data;
        }
      });
    },
    handleQuery() {
      GetOilTank({ siteId: this.siteId }).then(res => {
        if (res.code === 0) {
          // console.log('data', res.data);
          this.data = res.data;
        }
      });
    },
    back() {
      this.$router.go(-1);
    },

    handleSubmit() {
      this.$refs['form'].validate(async vaild => {
        if (vaild) {
          let tankerGun = [];
          this.tankId.map(item => {
            if (this.RowInfo[item]) {
              let tank = [];
              tank.push(item);
              tank.push(...this.RowInfo[item]);
              tankerGun.push(tank);
            }
          });
          let params = {
            siteId: this.siteId,
            num: this.RowInfo.num,
            meterNum: this.RowInfo.meterNum,
            tankHeight: this.RowInfo.tankHeight,
            type: this.RowInfo.type,
            inputTime: this.RowInfo.inputTime,
            oilCapacity: this.RowInfo.oilCapacity,
            tankLength: this.RowInfo.tankLength,
            tankHead: this.RowInfo.tankHead,
            tankerGun: tankerGun
          };
          let result;
          if (tankerGun.length > 0) {
            if (this.RowInfo.id) {
              result = await OilTankEdit({
                ...params,
                id: this.RowInfo.id
              });
            } else {
              result = await OilTankAdd(params);
            }
            if (result && result.code === 0) {
              this.$message.success(result.msg);
              this.handleCancel();
              return result;
            } else {
              this.$message.error(result.msg);
            }
          } else {
            this.$message.error('该加油站没有该油品加油机！');
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
      this.dialogAdd = false;
      this.RowInfo = {};

      this.editOilType = '';
      this.tankOptions = [];
      this.tankId = [];

      this.$refs['form'].resetFields();
      this.handleQuery();
    }
  }
};
</script>

<style scoped>
.page-header-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.tank-box {
  position: relative;
  padding-top: 18px;
}
.tank-box:hover .tank-icon {
  display: block;
}
.tank-box .tank-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  padding-right: 25px;
  /* background: #fff; */
}
.tank-box .tank-icon i {
  padding: 10px 5px;
  cursor: pointer;
  color: #000;
}
.tank-box .tank-icon i:hover {
  color: rgba(0, 0, 0, 0.6);
}
/* .tank-box .tank-icon i:nth-child(1) {
  color: #01adbe;
} */
.tank-box .tank-icon i:last-child {
  color: rgb(243, 94, 74);
}
</style>
