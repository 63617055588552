<template>
  <svg
    id="wave"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="72"
    height="210"
  >
    <rect width="100%" height="100%" rx="4" ry="4" fill="white" />
    <g fill="rgba(223, 216, 224, .1)">
      <path
        :d="
          `M 0 70 Q 75 39, 150 70 T 300 70 T 450 70 T 600 70 T 750 70 V 210 H 0 V ${height}`
        "
      ></path>
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="translate"
        from="0"
        to="-300"
        dur="3s"
        repeatCount="indefinite"
      ></animateTransform>
    </g>
    <g fill="rgba(202, 196, 206, 0.15)">
      <path
        :d="
          `M 0 70 Q 87.5 47, 175 70 T 350 70 T 525 70 T 700 70 T 875 70 T 1050 70 V 210 H 0 V ${height}`
        "
      ></path>
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="translate"
        from="0"
        to="-350"
        dur="6s"
        repeatCount="indefinite"
      ></animateTransform>
    </g>
    <g fill="rgba(118, 188, 196, 0.18)" transform="translate(-903.868 0)">
      <path
        :d="
          `M 0 70 Q 135 36, 270 70 T 540 70 T 810 70 T 1080 70 V 210 H 0 V ${height}`
        "
        transform="translate(-38.232284367796474, 0)"
      ></path>
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="translate"
        from="0"
        to="-540"
        dur="4s"
        repeatCount="indefinite"
      ></animateTransform>
    </g>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      height: 100
    };
  }
};
</script>

<style></style>
